.campaignsTable__status {
  display: flex;
  align-items: center;
}

.campaignsTable__status-badge {
  width: 15px;
  height: 15px;
  display: block !important;
  border-radius: 50% !important;
  margin-right: 10px;
}

.CampaignsTable__name {
  position: relative;

  span {
    position: absolute;
    left: 10px;
    top: 30px;
  }
}
