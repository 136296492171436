.campaign__details-list {
  > li {
    border: none !important;
  }

  > li:not(:last-child) {
    border-bottom: 2px solid #ebedef !important;
  }

  .statusBadge {
    width: 15px;
    height: 15px;
    display: inline-block !important;
    vertical-align: middle;
    border-radius: 50%;
    margin-right: 10px;
  }

  .campaign__timeoutFormElement {
    @media (max-width: 991px) {
      margin-bottom: 1rem;
    }
  }
}
