.Directories {
  .Directories__add-button {
    margin-left: auto;
    margin-right: 35px;

    @media(max-width: 577px) {
      margin-right: 0;
    }

    @media(max-width: 452px) {
      width: 100%;
      height: 35px
    }
  }

  .Directories__directories-header {
    @media(max-width: 452px) {
      flex-direction: column !important;
    }
  }

  .Directories__list-group {
    > li {
      border: none !important;
    }

    > li:not(:last-child) {
      border-bottom: 2px solid #ebedef !important;
    }
  }

  .Directories__input-group {
    @media (max-width: 576px) {
      display: flex !important;
      flex-direction: column !important;

      input {
        margin-top: 8px;
        width: 80% !important;
      }
    }
  }

  .Directory-Card__disable-enable-button {
    width: 100px !important;
  }

  .Directory-Card__info {
    display: flex !important;
    flex-direction: row !important;
    padding: 0 !important;

    span {
      width: 50% !important;
    }

    @media (max-width: 768px) {
      flex-direction: column !important;
      span {
        width: auto !important;
      }
    }
  }
}