.sidebarMinimizer {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  span {
    display: block;
    order: 1;
    color: rgba($color: #ffffff, $alpha: 0.4);
    flex-grow: 1;
    font-size: 12px;

    .c-sidebar-minimized & {
      display: none;
    }
  }

  &::before {
    order: 2;
  }
}
