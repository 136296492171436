.HeaderLanguageDropdown__dropdown-menu {
  min-width: 86px !important;
  padding: 0 !important;
  transform: translate(-13px, -1px) !important;
}

.HeaderLanguageDropdown__dropdown-item {
  min-width: auto !important;
  @media all and (max-width: 400px) {
    > .HeaderLanguageDropdown__long-language {
      display: inline;
    }
  }
}

.HeaderLanguageDropdown__long-language {
  display: inline;
  @media all and (max-width: 480px) {
    display: none;
  }
}

.HeaderLanguageDropdown__short-language {
  display: inline;
}