// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// Import flags
@import "./flags";

.dropdown_no-caret {
  .dropdown-toggle:after {
    content: none !important;
  }
}

.dropdown-menu {
  z-index: 950 !important;
}

.invalid-feedback_show {
  display: block !important;
}

.profile__avatar {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50em;
  @include avatar(100px, $avatar-xl-status-width);
}

.profile__blank-img {
  border-radius: 50%;
  background: rgb(216, 211, 211);
  @include avatar(100px, $avatar-xl-status-width);
}

.visually-hidden {
  position: absolute;
  right: 0px;
  top: 0px;
  font-family: Arial;
  font-size: 118px;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  opacity: 0;
  height: 100%;
}
