.MembersCard {
  .table-responsive {
    overflow-x: auto !important;
    overflow-y: visible !important;
  }

  .c-datatable-items-per-page {
    @media (max-width: 575px) {
      justify-content: flex-start;

      label {
        margin-bottom: 5px;
      }
    }
  }

  .c-datatable-filter {
    flex-wrap: nowrap;

    input {
      width: 86%;
    }

    @media (max-width: 575px) {
      margin-bottom: 5px;
      flex-wrap: wrap;

      label {
        margin-bottom: 5px;
      }
    }

    @media (min-width: 576px) {
      input {
        width: 230px;
      }
    }
  }

  .MembersCard__over-table-row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .members-table {
    .members-table__cell {
      vertical-align: middle !important;
    }

    .members-table__link {
      a {
        text-decoration: none !important;
        color: #3c4b64 !important;
      }

      a:hover {
        text-decoration: none !important;
        color: black !important;
      }
    }

    .members-table__phone {
      white-space: nowrap;
      hyphens: none;
    }
  }

  .members-table__pagination {
    &_hidden {
      display: none;
    }
  }

  .user-info-content {
    display: flex;
    align-items: center;

    .user-info-content__text {
      margin-left: 10px;
    }
  }
}


.over-table-row {
  display: flex;
  margin-bottom: 10px;
  justify-content: flex-start;

  .over-table-row__sorting-select {
    width: 330px;
    margin-bottom: 0 !important;

    @media (max-width: 575px) {
      width: 100%;
      margin-bottom: 10px !important;
    }
  }

  .over-table-row__add-button {
    width: 100px;

    @media (max-width: 575px) {
      width: 100%;
    }
  }
}